import { createCookieInHour, getCookie } from "./cookie";

export function leadActions(event) {
  const leadActionsCookie = getCookie("lead_actions")
    ? JSON.parse(getCookie("lead_actions"))
    : "";

  const newObj = {
    ...leadActionsCookie,
    [event]: true,
  };

  createCookieInHour("lead_actions", newObj, 10);
}

export function leadActionsArray() {
  const leadActionsCookie = getCookie("lead_actions")
    ? JSON.parse(getCookie("lead_actions"))
    : "";

  let array = [];
  let events_list = [
    "CLICK_WHATSAPP_CHAT",
    "CLICK_BOOK_VEHICLE",
    "CLICK_BOOK_TEST_DRIVE",
    "CLICK_VEHICLE_LOAN",
    "CLICK_SAVINGS_ESTIMATION",
    "CLICK_EV_CONSULTATION",
  ];

  events_list?.map((eventName) => {
    if (leadActionsCookie?.[eventName]) {
      let obj = {
        actionName: eventName,
      };
      array.push(obj);
    }
  });

  return array;
}
